import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Box from "../components/Box/Box";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";
import GridSection from "../components/GridSection";
import Layout from "../components/Layout/Layout";
import LinkArrowBtn from "../components/LinkArrowBtn";
import Seo from "../components/Seo";
import { Paragraph, Paragraph2, Title } from "../components/Styled/Text";
import Wrapper from "../components/Wrapper";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { WarrantyQuery } from "../types/pages/warranty";

interface Props extends PageProps {
  data: WarrantyQuery;
}

const Warranty = ({ data, location }: Props) => {
  const { height } = useWindowSize();
  return (
    <>
      <Seo
        title={data.translationsJson.pages.warranty.seo.title}
        description={data.translationsJson.pages.warranty.seo.description}
        keywords={data.translationsJson.pages.warranty.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.warranty.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#ffff",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                fontFamily: "custom_42266",
                fontSize: "21px",
                fontWeight: 400,
                color: get("palette.text.dark_gold"),
                marginBottom: "30px",
              }}
              as="span"
            >
              {data.translationsJson.pages.warranty.header.subtitle}
            </Box>
            {data.translationsJson.pages.warranty.header.title.map((t, i) => (
              <Title variant="bigHero" key={i} sx={{ color: "white" }}>
                {t}
              </Title>
            ))}

            <Paragraph2
              variant="hero"
              sx={{
                color: "#fff",
                opacity: 0.85,
                textAlign: ["center", "center", "center", "center"],
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.warranty.header.description}
            </Paragraph2>
          </Box>
          <GatsbyImageWithArtDirection
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Box>
        <Wrapper sx={{ margin: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 0,
              marginTop: ["70px", "70px", "200px", "200px"],
              marginBottom: ["70px", "70px", "200px", "200px"],
              textAlign: "center",
              padding: "0 7.5%",
            }}
          >
            {data.translationsJson.pages.warranty.responsibility.title.map(
              (t, i) => (
                <Title key={i} variant="section">
                  {t}
                </Title>
              )
            )}
            <Paragraph
              sx={{
                width: [
                  "100%",
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                ],
              }}
            >
              {data.translationsJson.pages.warranty.responsibility.text}
            </Paragraph>
          </Box>

          <GridSection
            firstChildrenType="textBox"
            secondChildrenType="imageBox"
          >
            <>
              {data.translationsJson.pages.warranty.responsibility.values.text.map(
                (d, i) => (
                  <Paragraph
                    key={i}
                    sx={{
                      paddingBottom: "10px",
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {d}
                  </Paragraph>
                )
              )}
            </>
            <>
              <Box
                as={GatsbyImage}
                alt={
                  data.translationsJson.pages.warranty.responsibility.values
                    .imageAlt
                }
                image={
                  data.translationsJson.pages.warranty.responsibility.values
                    .image.childImageSharp.gatsbyImageData
                }
              />
            </>
          </GridSection>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 0,
              textAlign: "center",
              padding: "0 7.5%",
              marginTop: ["70px", "70px", "200px", "200px"],
              marginBottom: ["0", "0", "200px", "200px"],
            }}
          >
            {data.translationsJson.pages.warranty.manufacture.title.map(
              (t, i) => (
                <Title key={i} variant="section" sx={{}}>
                  {t}
                </Title>
              )
            )}
            <Paragraph
              sx={{
                width: [
                  "100%",
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                ],
              }}
            >
              {data.translationsJson.pages.warranty.manufacture.text}
            </Paragraph>
          </Box>

          <GridSection
            firstChildrenType="imageBox"
            secondChildrenType="textBox"
          >
            <>
              <Box
                as={GatsbyImage}
                sx={{}}
                alt={
                  data.translationsJson.pages.warranty.manufacture.values[0]
                    .imageAlt
                }
                image={
                  data.translationsJson.pages.warranty.manufacture.values[0]
                    .image.childImageSharp.gatsbyImageData
                }
              />
            </>
            <>
              {data.translationsJson.pages.warranty.manufacture.values[0].text.map(
                (d, i) => (
                  <Paragraph
                    key={i}
                    sx={{
                      paddingBottom: "10px",
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {d}
                  </Paragraph>
                )
              )}
            </>
          </GridSection>

          <GridSection
            firstChildrenType="textBox"
            secondChildrenType="imageBox"
          >
            <>
              {data.translationsJson.pages.warranty.manufacture.values[1].text.map(
                (d, i) => (
                  <Paragraph
                    key={i}
                    sx={{
                      paddingBottom: "10px",
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {d}
                  </Paragraph>
                )
              )}
            </>
            <>
              <Box
                as={GatsbyImage}
                sx={{}}
                alt={
                  data.translationsJson.pages.warranty.manufacture.values[1]
                    .imageAlt
                }
                image={
                  data.translationsJson.pages.warranty.manufacture.values[1]
                    .image.childImageSharp.gatsbyImageData
                }
              />
            </>
          </GridSection>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: ["70px", "70px", "200px", "200px"],
              marginBottom: "20px",
            }}
          >
            {data.translationsJson.pages.warranty.notcover.title.map((t, i) => (
              <Title
                key={i}
                variant="paragraph"
                sx={{
                  textAlign: ["left", "left", "left", "left"],
                }}
              >
                {t}
              </Title>
            ))}
            {data.translationsJson.pages.warranty.notcover.items.map((d, i) => (
              <Paragraph
                key={i}
                sx={{
                  textAlign: ["left", "left", "left", "left"],
                  alignSelf: "center",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                - {d}
              </Paragraph>
            ))}
          </Box>
          <LinkArrowBtn
            to={data.translationsJson.pages.warranty.guide.publicURL}
            title={data.translationsJson.pages.warranty.guideTitle}
            as={Link}
            sx={{
              marginBottom: "40px",
              marginTop: "40px",
            }}
          />
        </Wrapper>
      </Layout>
    </>
  );
};

export default React.memo(Warranty);

export const warrantyQuery = graphql`
  query WarrantyQuery {
    headerDesktop: file(
      relativeDirectory: { eq: "pages/warranty/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/warranty/header" }
      name: { eq: "mobile" }
    ) {
      id
      relativeDirectory
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    translationsJson {
      pages {
        warranty {
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          header {
            subtitle
            title
            description
          }
          responsibility {
            title
            text
            values {
              title
              text
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
            }
          }
          manufacture {
            title
            text
            values {
              title
              text
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
            }
          }
          notcover {
            title
            items
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            imageAlt
          }
          guide {
            publicURL
          }
          guideTitle
        }
      }
    }
    site {
      siteMetadata {
        address
        addressLink
        email
        phone
      }
    }
  }
`;
